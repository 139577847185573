<template>
	<div class="page-web page_regi">
		<!-- <RegiHead /> -->
		<div class="regi-bd change_bd">
			<div class="regi_name">{{$t('acTit2')}}</div>
			<el-form ref="form" class="regi-from" :rules="rules" :model="form">
				<el-form-item prop="email">
					<div class="regi_group">
						<div class="regi_gr_t">{{$t('acTxt3')}}</div>
						<div class="regi_gr_b">
							<el-input v-model="form.email" placeholder=""></el-input>
						</div>
					</div>
				</el-form-item>
				<el-form-item prop="password">
					<div class="regi_group">
						<div class="regi_gr_t">{{$t('acTxt5')}}</div>
						<div class="regi_gr_b">
							<el-input v-model="form.password" type="password" placeholder="">
							</el-input>
						</div>
					</div>
				</el-form-item>
				<el-form-item prop="code">
					<div class="regi_group">
						<div class="regi_gr_t">{{$t('chTxt11')}}</div>
						<div class="regi_gr_b flex flex-ac">
							<el-input v-model="form.code" placeholder=""></el-input>
							<el-button class="btn btn_yzm" size="large"  type="primary" @click="sendEmail" :disabled="disabled">
								<span v-if="disabled">{{count}} S</span>
								<span v-if="!disabled">{{$t('code2')}}</span>
							</el-button>
						</div>
					</div>
				</el-form-item>
				<el-form-item>
					<div class="regi_group chan_btn flex flex-ac flex-between">
						<el-button class="btn btn_reset" size="large"  type="primary" @click="resetForm('form')">{{$t('reset')}}
						</el-button>
						<el-button class="btn" size="large"  type="primary" @click="submitForm('form')">{{$t('save')}}</el-button>
					</div>
				</el-form-item>
			</el-form>
			<div class="regi_tips">Copyright ©2024 Alcex All rights reserved.</div>
		</div>
	</div>
</template>

<script>
	import {
		bindEmail,
		bindEmailCode
	} from '@/api/api/user'
	import RegiHead from '@/components/RegiHead.vue'
	export default {
		components: {
			RegiHead,
		},
		data() {
			return {
				form: {},
				rules: {
					email: [{
						required: true,
						type: "email",
						message: this.$t("mailtip"),
						trigger: "blur"
					}],
					password: [{
						required: true,
						type: "string",
						min: 6,
						message: this.$t("chtip6"),
						trigger: "blur"
					}],
					code: [{
						required: true,
						message: this.$t("chTxt3"),
						trigger: "blur"
					}]
				},
				count: 60,
				disabled: false,
			};
		},
		created() {

		},
		mounted() {

		},
		methods: {
			submitForm(formName) {
				this.$refs[formName].validate((valid) => {
					if (valid) {
						bindEmail(this.form).then(res => {
							if (res.code == 0) {
								this.$message.success(this.$t('save_success'));
								this.$router.push('/admin')
							} else {
								this.$message.error(res.message);
							}
						})
					} else {
						console.log('error submit!!');
						return false;
					}
				});
			},
			resetForm(formName) {
				this.$refs[formName].resetFields();
			},
			sendEmail() {
				if (this.form.email) {
					bindEmailCode({
						email: this.form.email
					}).then(res => {
						if (res.code == 0) {
							let waitTime = 60;
							this.disabled = true
							let clock = window.setInterval(() => {
								this.count = waitTime
								waitTime--;
								if (waitTime <= 0) {
									waitTime = 60;
									this.disabled = false
									window.clearInterval(clock);
								}
							}, 1000);
						} else {
							this.$message.error(res.message);
						}
					})
				} else {
					this.$refs.form.validate("mail");
				}
			}
		}
	}
</script>
